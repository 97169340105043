import {
   shapeLinkRedirect,
   shapeTranslateBundle,
   shapeTranslateLink,
   shapeTranslateLocale,
} from '@/config/shapeValueObject'
import Locale from '@/config/locale'

export const localeLinkNavbar = {
   home: {
      ...shapeTranslateBundle(Locale.EN, 'Home', '/'),
      ...shapeTranslateBundle(Locale.ID, 'Beranda', '/'),
   },
   product: {
      ...shapeTranslateBundle(Locale.EN, 'Product', '/product'),
      ...shapeTranslateBundle(Locale.ID, 'Produk', '/produk'),
   },
   about: {
      ...shapeTranslateBundle(Locale.EN, 'About', '/info/about'),
      ...shapeTranslateBundle(Locale.ID, 'Tentang', '/info/tentang'),
   },
   contactUS: {
      ...shapeTranslateBundle(Locale.EN, 'Contact Us', '/info/contact'),
      ...shapeTranslateBundle(Locale.ID, 'Kontak', '/info/kontak'),
   },
   support: {
      ...shapeTranslateBundle(Locale.EN, 'Support', '/support'),
      ...shapeTranslateBundle(Locale.ID, 'Dukungan', '/dukungan'),
   },
   career: {
      ...shapeTranslateBundle(Locale.EN, 'Careers', '/info/career'),
      ...shapeTranslateBundle(Locale.ID, 'Karir', '/info/karier'),
   },
   blog: {
      ...shapeTranslateBundle(Locale.EN, 'Blog', '/info/blog'),
      ...shapeTranslateBundle(Locale.ID, 'Blog', '/info/blog'),
   }
}

export const localeLinkFooterInfoOne = [
   shapeTranslateLocale(
      shapeTranslateLink('Contact', '/info/contact'),
      shapeTranslateLink('Kontak', '/info/kontak')
   ),
   shapeTranslateLocale(
      shapeTranslateLink('Privacy Policy', '/info/privacy-policy'),
      shapeTranslateLink('Kebijakan Privasi', '/info/kebijakan-pribadi')
   ),
   shapeTranslateLocale(
      shapeTranslateLink('Peering Policy', '/info/peering-policy'),
      shapeTranslateLink('Kebijakan Peering', '/info/kebijakan-peering')
   ),
   shapeTranslateLocale(
      shapeTranslateLink('Visitor Policy', '/info/visitor-policy'),
      shapeTranslateLink('Kebijakan Kunjungan', '/info/kebijakan-pengunjung')
   ),
]

export const localeLinkFooterInfoTwo = [
   // shapeTranslateLocale(
   //     shapeTranslateLink('FAQ', '/info/faq'),
   //     shapeTranslateLink('FAQ', '/info/faq')
   // ),
   // shapeTranslateLocale(
   //     shapeTranslateLink('Speed Test', '/info/speed-test'),
   //     shapeTranslateLink('Tes Kecepatan', '/info/tes-kecepatan')
   // ),
   // shapeTranslateLocale(
   //     shapeTranslateLink('Sponsorship', 'mailto:hello@globalxtreme.net'),
   //     shapeTranslateLink('Sponsor', 'mailto:hello@globalxtreme.net')
   // ),
   shapeTranslateLocale(
      shapeTranslateLink('Blog', '/info/blog'),
      shapeTranslateLink('Blog', '/info/blog')
   ),
   shapeTranslateLocale(
      shapeTranslateLink('Career', '/info/career'),
      shapeTranslateLink('Karir', '/info/karier')
   ),
   shapeTranslateLocale(
      shapeTranslateLink('Customer Support', '/info/customer-support'),
      shapeTranslateLink('Dukungan Pelanggan', '/info/dukungan-pelanggan')
   ),
   shapeTranslateLocale(
      shapeTranslateLink(
         'Customer Technical Support',
         '/info/customer-technical-support'
      ),
      shapeTranslateLink(
         'Dukungan Teknis Pelanggan',
         '/info/dukungan-teknis-pelanggan'
      )
   ),
]

export const localeLinkFooterInfoThree = [
   shapeTranslateLocale(
      shapeTranslateLink('Terms and Conditions', '/info/term-and-conditions'),
      shapeTranslateLink('Syarat dan Ketentuan', '/info/syarat-dan-ketentuan')
   ),
   shapeTranslateLocale(
      shapeTranslateLink('Understanding Your Bill', '/info/understanding-bill'),
      shapeTranslateLink('Pemahaman Tagihan', '/info/pemahaman-tagihan')
   ),
]

export const localeLinkRedirect = {
   id: [
      shapeLinkRedirect('/product', '/produk'),
      shapeLinkRedirect('/product/lite', '/produk/lite'),
      shapeLinkRedirect('/product/signature', '/produk/signature'),
      shapeLinkRedirect('/product/dedicated', '/produk/dedicated'),
      shapeLinkRedirect('/about', '/tentang'),
      shapeLinkRedirect('/contact', '/kontak'),
      shapeLinkRedirect('/support', '/dukungan'),
   ],
}

export const localeLinkGeneral = {
   homePromotion: shapeTranslateLocale(
      shapeTranslateLink('', '/info/promotion'),
      shapeTranslateLink('', '/info/promosi')
   ),
   coverageArea: shapeTranslateLocale(
      shapeTranslateLink('Check Coverage', '/coverage-area'),
      shapeTranslateLink('Cek Jangkauan', '/area-cakupan')
   ),
   loadMorePackXLite: shapeTranslateLocale(
      shapeTranslateLink('Learn More', '/product/lite'),
      shapeTranslateLink('Pelajari Selengkapnya', '/produk/lite')
   ),
   loadMorePackSignature: shapeTranslateLocale(
      shapeTranslateLink('Learn More', '/product/signature'),
      shapeTranslateLink('Pelajari Selengkapnya', '/produk/signature')
   ),
   loadMorePackPreferred: shapeTranslateLocale(
      shapeTranslateLink('Learn More', '/product/preferred'),
      shapeTranslateLink('Pelajari Selengkapnya', '/produk/preferred')
   ),
   loadMorePackDedicated: shapeTranslateLocale(
      shapeTranslateLink('Learn More', '/product/dedicated'),
      shapeTranslateLink('Pelajari Selengkapnya', '/produk/dedicated')
   ),
   loadMorePackCustomize: shapeTranslateLocale(
      shapeTranslateLink('', '/product/customized-services'),
      shapeTranslateLink('', '/produk/customized-services')
   ),
   subscribeNow: shapeTranslateLocale(
      shapeTranslateLink('Subscibe Now', '/coverage-area'),
      shapeTranslateLink('Langganan Sekarang', '/coverage-area')
   ),
   requestCall: shapeTranslateLocale(
      shapeTranslateLink('Request a Call', '/coverage-area'),
      shapeTranslateLink('Buat Panggilan', '/coverage-area')
   )
}
