import { useEffect, createContext, useContext, useState } from 'react'
import _ from 'lodash'
import { LS_BRANCH, LS_LOCALE } from '@/config/localStorageKey'
import { contentBranches } from '@/content/cms/strapi.cms'
import { _convertArraySingleObject } from '@/helper/manageParameter'
import { shapeVOHook } from '@/config/shapeValueObject'
import { useRouter } from 'next/router'

const LayoutContext = createContext()

export const useHookLayoutContext = () => {
   const context = useContext(LayoutContext)
   const [branchs = [], setBranchs] = context.ctxBranchs
   const [localeCode, setLocaleCode] = context.ctxLocale
   const [selectedBranch, setSelectedBranch] = context.ctxBranch

   useEffect(() => {
      const newBranch = JSON.parse(localStorage.getItem(LS_BRANCH)) || {}
      setSelectedBranch((prevState) => ({
         ...prevState,
         ...newBranch,
      }))
   }, [])

   const _handleChooseBranch = (newBranch = {}) => {
      setSelectedBranch(newBranch)
      localStorage.setItem(LS_BRANCH, JSON.stringify(newBranch))
   }

   const _handleChooseLocale = (newLocale = '') => {
      setLocaleCode(() => newLocale)
      localStorage.setItem(LS_LOCALE, newLocale)
   }

   // useEffect(() => {
   //    alert('hello branch')
   //    if (_.isEmpty(branchs)) {
   //       contentBranches().then((resData) => {
   //          // console.log('resData branches : ', resData)
   //          if (resData?.data) {
   //             const newData = _convertArraySingleObject(resData)
   //             setBranchs(() => {
   //                return newData.map((vm) => ({
   //                   ...vm,
   //                   value: vm.id,
   //                   label: vm.name,
   //                }))
   //             })
   //          }
   //       })
   //    }
   // }, [])

   useEffect(() => {
      if (_.isEmpty(selectedBranch) && !_.isEmpty(branchs)) {
         const indexDefault = branchs.findIndex((vm) => vm.isDefault == true)
         if (indexDefault > -1) {
            _handleChooseBranch(branchs[indexDefault] || {})
         }
      }
   }, [branchs.length])

   return {
      __branch: {
         ...shapeVOHook(selectedBranch, _handleChooseBranch),
         list: !_.isEmpty(branchs) && _.isArray(branchs) ? branchs : [],
      },
      __locale: shapeVOHook(localeCode, _handleChooseLocale),
   }
}

export const useHookProduct = () => {
   const context = useContext(LayoutContext)
   const [selectedPackage, setSelectedPackage] = context.ctxPackage

   return {
      data: selectedPackage,
      choose: (newData = {}) => setSelectedPackage(newData),
      reset: () => setSelectedPackage({}),
   }
}

export const ContextWrappingLayout = ({ children }) => {
   const [branchs, setBranchs] = useState([])
   const [localeCode, setLocaleCode] = useState('en')
   const [selectedBranch, setSelectedBranch] = useState({})
   const [selectedPackage, setSelectedPackage] = useState({})

   useEffect(() => {
      setSelectedBranch(JSON.parse(localStorage.getItem(LS_BRANCH)) || {})

      if (localStorage.getItem(LS_LOCALE)) {
         setLocaleCode(() => localStorage.getItem(LS_LOCALE) || '')
      } else {
         localStorage.setItem(LS_LOCALE, 'en')
      }

      if (_.isEmpty(branchs)) {
         contentBranches().then((resData) => {
            // console.log('resData: ', resData)
            if (resData?.data) {
               const newData = _convertArraySingleObject(resData)
               setBranchs(() => {
                  return newData.map((vm) => ({
                     ...vm,
                     value: vm.id,
                     label: vm.name,
                  }))
               })
            }
         })
      }
   }, [])

   return (
      <LayoutContext.Provider
         value={{
            ctxBranchs: [branchs, setBranchs],
            ctxLocale: [localeCode, setLocaleCode],
            ctxBranch: [selectedBranch, setSelectedBranch],
            ctxPackage: [selectedPackage, setSelectedPackage],
         }}>
         {children}
      </LayoutContext.Provider>
   )
}
